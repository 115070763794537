import { Tour as TourAPI } from '@luxuryescapes/contract-svc-tour'

import { formatRoomType } from 'lib/tours/tourUtils'

import {
  extractAbsoluteDate,
  mapTourV2OfferType,
  tourV2BrandMap,
  tourV2ExperienceSnapshotMap,
  tourV2FinePrintMap,
  tourV2InclusionMap,
  tourV2ReservationSnapshotItineraryItemMap,
} from './tourV2OfferMapper'
import { TOUR_OPTIONAL_EXPERIENCE_RESERVATION_STATUS_CANCELLED, TOUR_V2_LE_SOURCE } from 'constants/tours'

export function tourV2SnapshotMap(
  snapshot: TourAPI.ReservationSnapshot,
): Tours.TourV2OfferSnapshot {
  return {
    offerType: mapTourV2OfferType(snapshot.source, snapshot.brand.code),
    bookingReference: snapshot.bookingReference,
    brand: tourV2BrandMap(snapshot.brand),
    countriesVisited: snapshot.countriesVisited ?? [],
    departureId: snapshot.departureId,
    description: snapshot.description,
    endDate: extractAbsoluteDate(snapshot.endDate),
    endLocation: snapshot.endLocation,
    finePrint: tourV2FinePrintMap(snapshot.finePrint),
    imageId: snapshot.imageId,
    inclusions: snapshot.inclusions?.map(tourV2InclusionMap) ?? [],
    itineraries: snapshot.itineraries?.map(tourV2ReservationSnapshotItineraryItemMap) ?? [],
    passengers: snapshot.passengers?.map(tourV2PassengerMap) ?? [],
    reservationId: snapshot.reservationId,
    rooms: snapshot.rooms.map(tourV2RoomMap),
    seasonName: snapshot.seasonName,
    startDate: extractAbsoluteDate(snapshot.startDate),
    startLocation: snapshot.startLocation,
    status: snapshot.status,
    totalPrice: snapshot.totalPrice,
    tourId: snapshot.tourId,
    tourName: snapshot.tourName,
    tourOptionId: snapshot.tourOptionId,
    source: snapshot.source,
    isLeTour: (snapshot.source === TOUR_V2_LE_SOURCE),
    operatedBy: snapshot.operatedBy!,
    notes: snapshot.notes,
    optionalExperiences: snapshot.optionalExperiences?.map(tourV2ExperienceSnapshotMap)
      ?.filter(tourOptionalExperience => tourOptionalExperience.status !== TOUR_OPTIONAL_EXPERIENCE_RESERVATION_STATUS_CANCELLED) ?? [],
  }
}

export function tourV2PassengerMap(
  passenger: TourAPI.Passenger,
): Tours.V2OfferSnapshotPassenger {
  const mappedPassenger: Tours.V2OfferSnapshotPassenger = {
    firstName: passenger.firstName,
    isLeadPassenger: !!passenger.isLeadPassenger,
    lastName: passenger.lastName,
    type: passenger.type,
  }

  if (passenger.dateOfBirth) {
    mappedPassenger.dateOfBirth = extractAbsoluteDate(passenger.dateOfBirth)
  }

  return mappedPassenger
}

export function tourV2RoomMap(
  room: TourAPI.Room | any, // TODO: update contract
): Tours.V2OfferSnapshotRoom {
  return {
    adultCount: room.adultCount,
    childAges: room.childAges,
    childCount: room.childCount,
    // @ts-ignore: field might be null
    roomType: formatRoomType(room.roomType), // TODO: avoid computed values
    specialRequests: room.specialRequests,
    occupant: {
      adults: room.adultCount,
      children: room.childCount,
      childrenAge: room.childAges,
    },
  }
}
